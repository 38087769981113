<template>
  <div class="flex flex-col flex-grow">
    <div class="flex-grow mb-4 px-10 lg:px-12">
      <div class="text-center text-20 mt-10 font-bold mb-4">
        Attention!
      </div>
      <div class="text-center font-bold mb-4 text-10">
        SPEND CONTROL
      </div>
      <template v-if="requestDebitCardError">
        <div class="alert alert-red-soft mt-6 mb-10">
          <span class="alert-icon">!</span>
          <span class="text-xs font-normal">
            {{ requestDebitCardError }}
          </span>
        </div>
      </template>
      <div class="text-left text-ash-700 font-thin mb-3 text-10 md:text-12">
        Hello {{ firstName }}
      </div>
      <div
        class="text-justify text-ash-700 font-thin mb-3 text-10 md:text-12"
      >
        Please note, in order to reinforce healthy credit habit within the
        CredPal community and prevent the biggest problem of today's credit
        card we have made a different kind of credit card which does not
        trap you in a continuous debt cycle.
      </div>
      <div
        class="text-justify text-gray-700 font-bold mb-3 text-10 md:text-12"
      >
        You cannot move your outstanding spent balance month on month. You
        are expected to clear all outstandings balance at the end of the
        month.
      </div>
      <div
        class="text-justify text-gray-700 font-bold mb-5 text-10 md:text-12"
      >
        If you would love to spread payment over a longer period of time.
        You can easily access a loan of up to 5million within the CredPal
        app.
      </div>

      <div class="bg-blue-500 flex flex-row mb-5 p-5 rounded-sm w-full">
        <svg
          width="27"
          height="27"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.16"
            cx="11.5"
            cy="11.5"
            r="11.5"
            fill="white"
          />
          <path
            d="M11.5098 16.2229C11.1878 16.2229 10.9105 16.1131 10.6779 15.8935C10.4543 15.6651 10.3425 15.3927 10.3425 15.0764C10.3425 14.7602 10.4543 14.4922 10.6779 14.2726C10.9105 14.053 11.1878 13.9432 11.5098 13.9432C11.8318 13.9432 12.1046 14.053 12.3282 14.2726C12.5518 14.4922 12.6636 14.7602 12.6636 15.0764C12.6636 15.3927 12.5518 15.6651 12.3282 15.8935C12.1046 16.1131 11.8318 16.2229 11.5098 16.2229ZM10.5974 9.42314V6.7085H12.4087V9.42314L12.2209 12.9021H10.7853L10.5974 9.42314Z"
            fill="white"
          />
        </svg>
        <h5 class="text-left text-white text-10 md:text-12 pl-3">
          After you clear your oustanding balance at the end of the month
          your card is automatically re-opened for use.
        </h5>
      </div>
    </div>

    <div
      class="
        bg-white flex flex-col gap-4 items-center justify-center
        h-150px p-5 relative rounded-b w-full
        sm:flex-row sm:pr-4 sm:pl-5 md:h-120px
      "
    >
      <div class="absolute bg-blue-200 h-2px left-0 top-0 w-full">
        <div
          class="bg-blue-500 h-full"
          :style="{ width: progressWidth }"
        />
      </div>

      <div class="flex">

        <div
          class="text-blue-400 bg-white alert-ash-soft text-left"
          style="flex: 0 0 22px; height: 22px; width: 22px"
        >
          <div class="bg-gray-200 text-center" style="border-radius: 50%">
            <span class="text-blue-500 font-bold">!</span>
          </div>
        </div>

        <div class="ml-2">
          <p class="text-10 lg:text-12 text-left text-gray-600">
            Kindly click on "I agree" to continue after you've carefully
            read through the above information.
          </p>
        </div>
      </div>
      <div class="w-full flex justify-center pt-5 md:pt-0 lg:w-4/10">
        <button
          class="btn btn-blue"
          @click.prevent="next"
        >
          I Agree
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  data() {
    return {
      progress: 30,
    };
  },
  computed: {
    progressWidth() {
      return `${this.progress}%`;
    },
    requestDebitCardError() {
      return null;
    },
  },
  mounted() {
    this.increaseProgress();
  },
  methods: {
    increaseProgress() {
      setTimeout(() => {
        this.progress = 50;
      }, 10);
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
